import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import {AffaireModel} from '../models/affaire.model'
@Injectable({
  providedIn: 'root'
})
export class AffaireService {
  urlListAffaires: string;
  urlCodeAffaire: string
  urlOneAffaire: string
  urlUpdateAffaire: string


  // check list
  public editDataDetails: any = [];
  private messageSource = new BehaviorSubject(this.editDataDetails);
  currentMessage = this.messageSource.asObservable();

// events
  selectedLine = new Subject();
errorEvent = new Subject()
    selectLineEvent = new Subject()
  eventBool = new BehaviorSubject(null)
  selectedEvent = new Subject()
  selectedLineCheckListEvent = new Subject()
  selectedLinePauseEvent = new Subject()
  selectedLineStopEvent = new BehaviorSubject(null)
  durationEvent = new Subject()
  getAffaireEvent = new Subject()






  constructor(private http: HttpClient) {
    this.urlListAffaires = environment.urlListAffaires;
    this.urlCodeAffaire = environment.urlCodeAffaire

    this.urlOneAffaire = environment.urlOneAffaire
    this.urlUpdateAffaire = environment.urlUpdateAffaire

   }
   selectAll(event) {
    this.messageSource.next(event);
  }
   getAffaires() {
    return this.http.get<any>(this.urlListAffaires);
  }
  
   

  getOneAffaire(data) {
    return this.http.post<any>(this.urlOneAffaire, data); 
  }
  updateAffaire(data,id) {
    return this.http.put<any>(this.urlUpdateAffaire + id, data);
  }

  codeAffaire(data) {
    return this.http.post<any>(this.urlCodeAffaire, data); 
  }
 
 
}
