<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"> Expert Judiciaire</a>
    <img id="justice" src="../../../../assets/images/justice1.jpg" alt="" />
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <nb-select [selected]="currentlang" (selectedChange)="changelang($event)" status="primary" class="p-3">
    <nb-option *ngFor="let lang of langs" [value]="lang.value"> {{ lang.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">
    <!-- cards header -->

    <nb-action class="control-item" *ngIf="!bonjourBool">
      <div class="boxe" [routerLink]="arrayMenu[0].link">
        <div class="boxe1">
          <nb-icon
            [icon]="arrayMenu[0].icon.icon"
            [pack]="arrayMenu[0].icon.pack"
          ></nb-icon>
        </div>
        <div class="boxe2">Agenda</div>
      </div>
    </nb-action>
    <nb-action class="control-item" *ngIf="!bonjourBool">
      <div class="boxe" [routerLink]="arrayMenu[1].link">
        <div class="boxe1">
          <nb-icon
            [icon]="arrayMenu[1].icon.icon"
            [pack]="arrayMenu[1].icon.pack"
          ></nb-icon>
        </div>
        <div class="boxe2">Liste Dossiers</div>
      </div>
    </nb-action>
    <nb-action class="control-item" *ngIf="!bonjourBool">
      <div class="boxe" [routerLink]="arrayMenu[2].link">
        <div class="boxe1">
          <nb-icon
            [icon]="arrayMenu[2].icon.icon"
            [pack]="arrayMenu[2].icon.pack"
          ></nb-icon>
        </div>
        <div class="boxe2">Feuille de Temps</div>
      </div>
    </nb-action>
    <nb-action class="control-item" *ngIf="!bonjourBool">
      <div class="boxe" [routerLink]="arrayMenu[3].link">
        <div class="boxe1">
          <nb-icon
            [icon]="arrayMenu[3].icon.icon"
            [pack]="arrayMenu[3].icon.pack"
          ></nb-icon>
        </div>
        <div class="boxe2">Ajout Dossier</div>
      </div>
    </nb-action>
    
    <nb-action class="control-item" *ngIf="!bonjourBool && access">
      <div class="boxe" [routerLink]="arrayMenu[4].link">
        <div class="boxe1">
          <nb-icon
            [icon]="arrayMenu[4].icon.icon"
            [pack]="arrayMenu[4].icon.pack"
          ></nb-icon>
        </div>
        <div class="boxe2">Profile</div>
      </div>
    </nb-action>
    <!-- <nb-action class="control-item" *ngIf="!bonjourBool">
      <div class="boxe" [routerLink]="arrayMenu[5].link">
        <div class="boxe1">
          <nb-icon
            [icon]="arrayMenu[5].icon.icon"
            [pack]="arrayMenu[5].icon.pack"
          ></nb-icon>
        </div>
        <div class="boxe2">Profile</div>
      </div>
    </nb-action> -->
    <!-- bonjour -->
    <nb-action class="control-item">
      <div class="boxeBonj" *ngIf="bonjourBool">
        <div>Bonjour {{ username }}</div>
      </div>
    </nb-action>
    <!-- select langue -->
    <!-- <nb-action class="control-item">
      <div
        class="lang-menu"
        [matTooltip]="langue"
        matTooltipClass="red-tooltip"
        #tooltip="matTooltip"
      >
        <div class="selected-lang" (click)="toggleLang($event)"></div>
        <ul *ngIf="boolLang" (click)="$event.stopPropagation()">
        
          <li>
            <a
              (click)="onUpdateValue('en')"
              (mouseenter)="tooltip.hide()"
              class="en"
            >
              <div class="check">
                <img src="../../../../assets/images/usa1.png" alt="" />

                <p [ngClass]="{ bold: selectedLang1 }">
                
                  ANGLAIS
                </p>
              </div>
              <img
                src="../../../../assets/images/check1.png"
                *ngIf="selectedLang1"
              />
            </a>
          </li>
          <li>
            <a
              (click)="onUpdateValue('fr')"
              class="fr"
              (mouseenter)="tooltip.hide()"
            >
              <div class="check">
                <img src="../../../../assets/images/france.png" alt="" />

                <p [ngClass]="{ bold: selectedLang2 }">
                
                  FRANCAIS
                </p>
              </div>
              <img
                src="../../../../assets/images/check1.png"
                *ngIf="selectedLang2"
              />
            </a>
          </li>
          <li>
            <a
              (click)="onUpdateValue('ar')"
              class="ar"
              (mouseenter)="tooltip.hide()"
            >
              <div class="check">
                <img
                  style="width: 32px; height: 22px"
                  src="../../../../assets/images/arabe.png"
                  alt=""
                />

                <p [ngClass]="{ bold: selectedLang3 }">
               
                  ARABE
                </p>
              </div>
              <img
                src="../../../../assets/images/check1.png"
                *ngIf="selectedLang3"
              />
            </a>
          </li>
       
        </ul>
      </div>
    </nb-action> -->
    <!-- ********************** -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.name"
        [picture]="user?.picture"
      >
      </nb-user>
    </nb-action>
    <nb-action class="control-item logout">
      <img
        src="../../../../assets/images/logout.png"
        alt=""
        (click)="logout()"
        [matTooltip]="deconnexion"
        matTooltipClass="red-tooltip"
      />
    </nb-action>
  </nb-actions>
</div>
