import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  userIdEvent = new BehaviorSubject(null)
  constructor() { }
}
