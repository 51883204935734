import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  NbLayoutDirection,
  NbLayoutDirectionService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { filter, map, takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { NbAuthService, NbTokenService } from "@nebular/auth";
// import { UserDataService } from "app/pages/services/user.service";
import { MenuTranslateService } from "app/pages/menu-translate.service";
// import { PagesService } from "app/pages/services/pages.service";
// import { MainService } from "app/shared/services/main.service";
// import { HeaderService } from "./header.service";
import { createFalse } from "typescript";
import { UserAuthService } from "app/pages/services/user-auth.service";
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  dashboard: string = "DASHBOARD";
  font_size_menu = "20px";
  langue: string = "Langue"
  devise: string;
  app_name: string;
  deconnexion: string;
  consumed: number = 123445;
  total: number = 230000;
  percentage: string;
  modules;
  username: any;
  subModule: Subscription;
  selectedLang1: boolean = false;
  selectedLang2: boolean = true;
  selectedLang3: boolean = false;
  selectedLang4: boolean = false;
  bonjourBool: boolean = true
  selected1: boolean = false;
  selected2: boolean = false;
  selected3: boolean = true;
  selected4: boolean = false;
  selected5: boolean = false;
  boolLang: boolean = false;
  boolCurrency: boolean = false;
  selectedItem = "fr";
  userAppName: string;
  dev;
  res: any;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  access: boolean = false
  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";
  userId: number;
  userMenu = [{ title: "Profile" }, { title: "Log out" }];
  tag = "ngx-header";
  credit: string;
  consomme: string;
  arrayMenu = [
    // {
    //   title: "Dashboard",
    //   link: "/pages/dashboard",
    //   icon: { icon: "todolist", pack: "myicon" },
    // },
    {
      title: "Agenda",
      link: "/pages/agenda",
      icon: { icon: "agenda", pack: "myicon" },
    },

    {
      title: "Liste Dossiers",
      link: "/pages/dossiers",
      icon: { icon: "dossier", pack: "myicon" },
    },
    {
      title: "Feuille de Chronométrage",
      link: "/pages/chrono",
      icon: { icon: "sand", pack: "myicon" },
    },

    {
      title: "Ajout Dossier",
      link: "/pages/dossier/ajout-dossier",
      icon: { icon: "ajout", pack: "myicon" },
    },
    {
      title: "Profile",
      link: "/pages/settings",
      icon: { icon: "admin", pack: "myicon" },
    },
  ];
  constructor(
    private userAuthService: UserAuthService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public translateService: TranslateService,
    private router: Router,
    private service: NbAuthService,
    // private userDataService: UserDataService,
    private tokenService: NbTokenService,
    private menuTranslateService: MenuTranslateService,
    // private pagesService: PagesService,
    // private mainService: MainService,
    private directionService: NbLayoutDirectionService
  ) // private headerService: HeaderService,

  {
    console.log("role in header", sessionStorage.getItem("role"))
    if (sessionStorage.getItem("role") == "admin") {
      this.access = true


    }

    setTimeout(() => {
      this.bonjourBool = false
    }, 2000);
    // this.authService.getToken().subscribe((token) => {
    //   this.userId = token.getPayload().id;
    // });

    // translateService.addLangs(["en", "fr", "ar", "de"]);
    // this.translateService.setDefaultLang("fr");

    // if (sessionStorage.getItem("language") === "ar") {
    //   this.directionService.setDirection(NbLayoutDirection.RTL);
    //   this.selectedLang1 = false;
    //   this.selectedLang2 = false;
    //   this.selectedLang3 = true;
    //   this.selectedLang4 = false;
    // } else if (sessionStorage.getItem("language") === "fr") {
    //   this.directionService.setDirection(NbLayoutDirection.LTR);
    //   this.selectedLang1 = false;
    //   this.selectedLang2 = true;
    //   this.selectedLang3 = false;
    //   this.selectedLang4 = false;
    // } else if (sessionStorage.getItem("language") === "en") {
    //   this.directionService.setDirection(NbLayoutDirection.LTR);
    //   this.selectedLang1 = true;
    //   this.selectedLang2 = false;
    //   this.selectedLang3 = false;
    //   this.selectedLang4 = false;
    // } else if (sessionStorage.getItem("language") === "de") {
    //   this.directionService.setDirection(NbLayoutDirection.LTR);
    //   this.selectedLang1 = false;
    //   this.selectedLang2 = false;
    //   this.selectedLang3 = false;
    //   this.selectedLang4 = true;
    // }
    // this.translateService.stream("MENU.DASHBOARD").subscribe((res) => {
    //   this.dashboard = res;
    // });
    // this.translateService.stream("HEADER.APPNAME").subscribe((res) => {
    //   this.app_name = res;
    // });
    // this.translateService.stream("HEADER.DEV").subscribe((res) => {
    //   this.dev = res;
    // });
    // this.translateService.stream("HEADER.CREDIT").subscribe((res) => {
    //   this.credit = res;
    // });
    // this.translateService.stream("HEADER.CONSOMME").subscribe((res) => {
    //   this.consomme = res;
    // });
    // // this.translateService.stream("TOOLTIP.LANGUE").subscribe((res) => {
    // //   this.langue = res;
    // // });
    // this.translateService.stream("TOOLTIP.DEVISE").subscribe((res) => {
    //   this.devise = res;
    // });
    // this.translateService.stream("TOOLTIP.DECONNEXION").subscribe((res) => {
    //   // this.deconnexion = res;
    // });
    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === this.tag))
      .subscribe((bag) => {
        if (bag.item.title === "Log out") {
          this.router.navigate(["/auth/logout"]);
          this.tokenService.clear();
        } else if (bag.item.title === "Profile") {
          this.router.navigate(["pages/profile"]);
        }
      });

  }

  ngOnInit() {

    let username = sessionStorage.getItem("username")
    this.username = username
    this.user = {
      name: username,
      picture: "../../../../assets/images/bessam.png",
    };
    // console.log(this.user);


    this.deconnexion = "Déconnexion";
    let percentage = (this.consumed / this.total) * 100;
    this.percentage = percentage.toString().slice(0, 2) + "%";
    this.currentTheme = this.themeService.currentTheme;
    // this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
    //   console.log({result})

    // })

    // this.userService
    //   .getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }
  ngOnChanges() {
    let menu = this.menuTranslateService.MENU_ITEMS.slice();


  }
  ngAfterViewInit() {
    this.ngOnChanges();
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.selected1 = false;
    this.selected2 = false;
    this.selected3 = true;
    // this.subModule.unsubscribe();

    // this.tokenService.clear();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }
  //   onNavigate() {
  // this.router.navigate([])
  //   }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  onUpdateValue(event) {
    // console.log(event);
    if (event === "en") {
      this.selectedLang1 = true;
      this.selectedLang2 = false;
      this.selectedLang3 = false;
      this.selectedLang4 = false;
      this.directionService.setDirection(NbLayoutDirection.LTR);
      // this.headerService.arabeEvent.next('english')
      // sessionStorage.setItem("language","en" )
    } else if (event === "fr") {
      this.selectedLang1 = false;
      this.selectedLang2 = true;
      this.selectedLang3 = false;
      this.selectedLang4 = false;

      this.directionService.setDirection(NbLayoutDirection.LTR);
      // this.headerService.arabeEvent.next('french')
      // sessionStorage.setItem("language","fr" )
    } else if (event === "de") {
      this.selectedLang1 = false;
      this.selectedLang2 = false;
      this.selectedLang3 = false;
      this.selectedLang4 = true;

      this.directionService.setDirection(NbLayoutDirection.LTR);
      // this.headerService.arabeEvent.next('german')
      // sessionStorage.setItem("language","de" )
    } else if (event === "ar") {
      this.selectedLang1 = false;
      this.selectedLang2 = false;
      this.selectedLang3 = true;
      this.selectedLang4 = false;

      // this.headerService.arabeEvent.next('arabe')

      this.directionService.setDirection(NbLayoutDirection.RTL);
      sessionStorage.setItem("language", "ar");
    }
    if (event) {
      this.translateService.use(event);
    }
  }

  toggleLang($event) {
    $event.stopPropagation();
    this.boolLang = !this.boolLang;
    this.boolCurrency = false;
  }

  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    this.boolLang = false;
    this.boolCurrency = false;
  }
  logout() {
    this.router.navigate(["/auth/logout"]);
    this.tokenService.clear();
    sessionStorage.clear(); // Clear all session storage
    localStorage.clear(); // Clear all local storage
    sessionStorage.setItem("language", "fr");
    this.directionService.setDirection(NbLayoutDirection.LTR);
    window.location.href = "http://groupeware.dotcom.tn";
    // this.authService.logout("email");
  }
}
