<div class="container">
  <h1 id="title" class="title">Connectez-vous</h1>
  <p class="sub-title">Entrez vos identifiants</p>

  <nb-alert
    *ngIf="showMessages.error && errors?.length && !submitted"
    outline="danger"
    role="alert"
  >
    <p class="alert-title"><b>Problème</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <nb-alert
    *ngIf="showMessages.success && messages?.length && !submitted"
    outline="success"
    role="alert"
  >
    <p class="alert-title"><b>Félicitation</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">
        {{ message }}
      </li>
    </ul>
  </nb-alert>

  <div [formGroup]="loginForm" aria-labelledby="title">
    <div class="form-control-group">
      <label class="label" for="input-email">Email</label>
      <input
        nbInput
        fullWidth
        id="input-email"
        spellcheck="false"
        formControlName="email"
        [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : ''"
        [attr.aria-invalid]="email.invalid && email.touched ? true : null"
        placeholder="Email address"
      />
      <ngx-validation-message
        label="Email address"
        [showPattern]="email?.hasError('pattern') && email.touched"
        [showRequired]="email?.errors?.required && email.touched"
      ></ngx-validation-message>
    </div>

    <div class="form-control-group">
      <span class="label-with-link">
        <label class="label" for="input-password">Mot de passe</label>
        
      </span>
      <input
        nbInput
        fullWidth
        id="input-password"
        formControlName="password"
        type="password"
        [status]="
          password.dirty ? (password.invalid ? 'danger' : 'success') : ''
        "
        placeholder="Password"
      />
     
    </div>

   

    <button
      nbButton
      fullWidth
      status="primary"
      size="large"
      (click)="login()"
      (keypress) ="login()"
      [disabled]="submitted || !loginForm.valid"
      [class.btn-pulse]="submitted"
    >
      Entrez
    </button>
  </div>
</div>
