import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {

  transform(value, data) {
    let result;
    for (let j = 0; j < data.length; j++) {
      if (value === data[j].id_user) {
        result = data[j].username;
      }
    }
    return result;
  }

}
