import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class PagesService {
  public url = environment.apiUrl
  public id_user: any;

  constructor(private http: HttpClient, protected service: NbAuthService,) {
    // this.currentUser = this.authenticationService.currentUserValue;
    // this.user=this.currentUser.objet
    this.id_user = window.localStorage.getItem('id_user')
  }

  creategroupe(groupe) {
    return this.http.post(`${this.url}/creategroupe`, groupe)
  }
  listGroupes() {
    return this.http.get(`${this.url}/listgroupe`)
  }

  getsidebar() {
    return this.http.get(`${this.url}/listsidebar`)
  }

  gettribunaux() {
    return this.http.get(`${this.url}/gettribunaux`)
  }
  getsectiontribunal() {
    return this.http.get(`${this.url}/getlistSection`)
  }
  getsectionpartribunal(id_trib: number, data) {
    console.log("we are in getsectionpartribunal")

    let tab = []
    data.forEach(el => {
      if (el.id_trib == id_trib) {
        tab.push(el)
      }
    })

    console.log("tab in getsectionpartribunal", tab)
    return tab;
  }
  allowpermission(id_side, groupe) {
    // /api/allowpermission
    return this.http.put(`${this.url}/allowpermission/` + id_side, groupe)
  }
  getprofile() {
    // console.log(`id_user`, window.localStorage.getItem('id_user'))
    return this.http.get(`${this.url}/profile/` + window.localStorage.getItem('id_user'))
  }
  activateUser(id, obj): Observable<any> {
    //this.iduser= window.localStorage.getItem('id_user`);
    return this.http.put<any>(`${this.url}/activUser/` + id, obj)
  }
  getUserNonActiv(): Observable<any> {

    return this.http.get<any>(`${this.url}/customers_nonActiv`)
  }
  private handleError(err: HttpErrorResponse | any) {
    console.error('An error occurred', err);
    return throwError(err.message || err);
  }
  step1(form: any): Observable<any> {
    // console.log('url', `${this.url}/form`)
    return this.http.post<any>(`${this.url}/form`, form)
      .pipe(
        catchError(this.handleError)
      );
  }
  ajoutDoc(obj, id) {

    // console.log('url', `${this.url}/form`)
    return this.http.post<any>(`${this.url}/document/` + id, obj)
      .pipe(
        catchError(this.handleError)
      );
  }
  UpdateStep1(form: any, id): Observable<any> {
    // console.log('url', `${this.url}/updateFile/` + id)
    return this.http.put<any>(`${this.url}/updateFile/` + id, form)
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadFil(url: string, file: File): Observable<any> {
    // console.log(`url to add pdf file: `, url)
    let formData = new FormData();
    formData.append('pdffile', file);
    return this.http.post(url, formData);
  }

  uploadFil2(url: string, file: File): Observable<any> {
    let formData = new FormData();
    formData.append('excelfile', file);
    return this.http.post(url, formData);
  }

  CreateDocx(url: string, obj: any): Observable<any> {
    return this.http.post(url, obj);
  }
  GetResume(id_form) {
    return this.http.get<any>(`${this.url}/gettAllInf/` + id_form)
      .pipe(
        catchError(this.handleError)
      );
  }
  Validate(form, id_form) {
    return this.http.put<any>(`${this.url}/valid/` + id_form, form)
      .pipe(
        catchError(this.handleError)
      );
  }
  duration(obj) {
    // console.log('url duration', `${this.url}/duration`)
    return this.http.post<any>(`${this.url}/duration`, obj)
      .pipe(
        catchError(this.handleError)
      );
  }
  GetUsers() {
    return this.http.get<any>(`${this.url}/customers`)
  };
  accessToRead(id_form, obj): Observable<any> {
    return this.http.post<any>(`${this.url}/write/` + id_form, obj)
  };
  accessToWrite(id_form, obj): Observable<any> {
    // console.log('url', `${this.url}/write/` + id_form)
    return this.http.post<any>(`${this.url}/write/` + id_form, obj)
  }
  addUserToGroupe(id, groupe) {

    return this.http.put<any>(`${this.url}/customers/` + id, groupe)
  }
  /* uploadDoc(url: string, file: File): Observable<HttpEvent<any>> {
    console.log(`url to add pdf file: `,url)

    let formData = new FormData();
    formData.append('document`, file);
    
    let params = new HttpParams();
 
    const options = {
      params: params,
      reportProgress: true,
    };
    const req = new HttpRequest('POST`, url, formData)
    return this.http.request(req);
  } */

  GetDocs(id_form) {
    // console.log(`url`, `${this.url}/getListArr/` + id_form)
    return this.http.get<any>(`${this.url}/getListArr/` + id_form)

  };

  liste(): Observable<any> {
    return this.http.get<any>(`${this.url}/getListFiles`)

  };
  // *******************AGENDA****************************
  //********************************************************* */
  Addevent(form: any): Observable<any> {
    // console.log('url', `${this.url}/createEvents`)
    return this.http.post<any>(`${this.url}/createEvents`, form)

  };

  GetEvents() {
    return this.http.get<any>(`${this.url}/getEvents`)

  };

  ModifEvents(id, form) {
    // console.log('url', `${this.url}/updateEvent/`)
    return this.http.put<any>(`${this.url}/updateEvent/` + id, form)

  };

  DeleteEvent(idevent) {
    // console.log("urldelete: ", `${this.url}/deleteEvent/` + idevent)
    return this.http.delete<any>(`${this.url}/deleteEvent/` + idevent)

  };
  //********************************************************* */
  //********************************************************* */



  getUsersByProjects(): Observable<any> {
    // console.log('url', `${this.url}/projectByUser`)
    return this.http.get<any>(`${this.url}/projectByUser`)
  }

  getProfil(): Observable<any> {

    // console.log('url', `${this.url}/profile/` + window.localStorage.getItem('id_user'))
    return this.http.get<any>(`${this.url}/profile/` + window.localStorage.getItem('id_user'))
  }

  //this.urlValidation=environment.urlValidation

  PostForm(form: any): Observable<any> {
    //  console.log('url',`${this.url}/profile`)
    return this.http.post<any>(`${this.url}/profile`, form)
  }

  private subject = new Subject<any>();

  clearMessage() {
    this.subject.next();
  }


  sendMessage(form) {
    this.subject.next({ form: form })
    //  console.log(form)

  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();

  }
}

// apiEndpoint: `${this.url}/login`,
// urlInscription: `${this.url}/customers`,
// urlAllusers: `${this.url}/customers`,
// urlactivation: `${this.url}/activUser/`,
// urlValidation: `${this.url}/profile`,
// urlProfile: `${this.url}/profile/`,
// urlforgot: `http://localhost:5000/users/forgote`,
// urlnewPsw: `http://localhost:5000/users/updatepassword`,
// urlstep1: `${this.url}/form`,
// urlstep2_pdf: `${this.url}/uploadpdf`,
// urlstep2_excel: `${this.url}/uploadexcel`,
// urlRsume: `${this.url}/gettAllInf/`,
// urlValidate:`${this.url}/valid/`,
// urlListedossiers:`${this.url}/getListFiles`,
// urlfiles:`${this.url}/file/`,
// urlduration:`${this.url}/duration`,
// urladdevent:`${this.url}/createEvents`,
// urlgetEvents:`${this.url}/getEvents`,
// urlmodifEvent:`${this.url}/updateEvent/`,
// urlDeleteEvent:`${this.url}/deleteEvent/`,
// urlgetUsersNonActiv:`${this.url}/customers_nonActiv`,
// urlaceccToRead:`${this.url}/read/`,
// urlacessToWrite:`${this.url}/write/`,
// urlProjectByUser:`${this.url}/projectByUser`,
// urlFile:`http://localhost:5000/getFile`,
// urluploadDoc:`${this.url}/document/`,
// urlgetDoc:`${this.url}/getListArr/`,
// urlmodifFile:`${this.url}/updateFile/'
