import { HttpClient } from "@angular/common/http";
import { Injectable, OnChanges, OnInit } from "@angular/core";
import { NbAuthService } from "@nebular/auth";
import { NbMenuItem } from "@nebular/theme";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { PagesService } from "./pages.service";
@Injectable({
  providedIn: "root",
})
export class MenuTranslateService implements OnInit, OnChanges {
  urlMenu: string;
  hideBool: boolean = true
  access_role: boolean = true
  MENU_ITEMS: NbMenuItem[] = []
  constructor(
    private translateService: TranslateService,
    private authService: NbAuthService,
    private http: HttpClient,
    private userService: PagesService
  ) {

    if (sessionStorage.getItem("role") == "admin") {
      this.access_role = false
      console.log("role in menu if is ", sessionStorage.getItem("role"))

     

    }
   
    this.MENU_ITEMS = [
      {
        title: "Dashboard",
        link: "/pages/dashboard",
        icon: { icon: "todolist", pack: "myicon" },
      },
      {
        title: "Agenda",
        link: "/pages/agenda",
        icon: { icon: "agenda", pack: "myicon" },
      },
      {
        title: "Liste Dossiers",
        link: "/pages/dossiers",
        icon: { icon: "dossier", pack: "myicon" },
      },
      {
        title: "Feuille de Temps",
        link: "/pages/chrono",
        icon: { icon: "sand", pack: "myicon" },
      },
      {
        title: "Ajout Dossier",
        link: "/pages/dossier/ajout-dossier",
        icon: { icon: "ajout", pack: "myicon" },
      },
      {
        title: "Profile",
        link: "/pages/settings",
        icon: { icon: "admin", pack: "myicon" },
        hidden: this.access_role
      },

    ];
    this.userService.GetUsers().subscribe(users => {
      if (users) {
        let username = sessionStorage.getItem("username")
        console.log("sessionStorage******", sessionStorage)

        for (let i = 0; i < users.length; i++) {
          if (users[i].username === username && users[i].id_groupe !== "7") {
            this.hideBool = true
          }
        }
      }
    })

  }



  ngOnChanges() { }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
  // getModules(id) {
  //   return this.http.get<any>(this.urlMenu + id);
  // }
}
